@import url(https://fonts.googleapis.com/css2?family=var(--quick-sand-font):wght@300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=var(--quick-sand-font):wght@300;400;500;600;700&display=swap);
/* Google Fonts */
/* Basic Style */
:root {
  --quick-sand-font: 'Quicksand';
  --purple-color:#5819B8;
  --font-color:#121212;
  --black-color: #000;
  --primary-brand-color: rgb(241,169,28);
  --secondary-brand-color: green
}

/*---------*/


html body{
  margin:0;
  padding:0;
  -ms-word-wrap:break-word;
  word-wrap:break-word;
  background-color:#fff;
  position:relative;
  font-size: 20px;
  color: var(--black-color);
  overflow-x: hidden;
  font-family: var(--quick-sand-font);
}
label {
  margin-bottom: 0 !important;
}
img{
  margin:0;
  padding:0;
  border:none;
  max-width:100%;
  height:auto;
}
section img{
  max-width:100%;
}
h1,h2,h3,h4,h5,h6{
  margin:0;
  padding:10px 0;
  line-height: 1.4;
  letter-spacing: 1px;
  font-family: source sans pro;
  font-weight: 600;
}
p{
  margin:0 0;
  padding:0;
  font-size:20px;
  font-weight:normal;
  font-family: source sans pro;
}
ul li a{
  letter-spacing: 0px;
  font-family: var(--quick-sand-font);
  color: var(--font-color);
}
body a{
  text-decoration:none;
  color: var(--font-color);
}
a:hover, a:focus{
  text-decoration:none !important;
}
ul,ol{
  margin:0 0 0 15px;
  padding:0;
  list-style: none;
}
.training-list li {
  display: inline-block;
  margin:10px;
  font-size: 20px;
  border: var(--primary-brand-color);
  border-style: solid;
  border-width: 1px;
  padding: 20px;
  border-radius: 15px;
  width: -webkit-fill-available;
  max-width: 510px;
  min-width: 300px;
}
small{
  font-size:14px !important;
}

textarea{
  border:1px solid #424648;
  font-size:16px;
  padding:10px 10px;
  margin:0 0 0px 0;
  height:auto;
  width: 100%;
  background: transparent;
}
section{
  background-size: cover;
  background-repeat: no-repeat;
  padding: 50px 0;
}
select{
  width: 100%;
  padding: 6px;
}
::selection {
    background: var(--theme-primary-color);
    color: #fff;
}
.fa-solid {
  font-weight: 600;
  font-family: 'Font Awesome 5 Free';
}
.sticky{
  margin:0;
  padding:0;
  background-color:#d7d7d7;
}

.footer {
    color: #fff;
    font-size: 20px;
    margin-bottom: 0px;
    margin-top: 20px;
    padding: 10px 10px 0px 10px;
    bottom: 0;
    width: 100%;
    /* Height of the footer*/
    padding-bottom: 20px;
}

.footer .nav-link {
  color: var(--primary-brand-color)
}

.copy-right-footer {
  background-color: #d7d7d7;
  color: #000;
}

.main_title h1 {
  font-size: 40px;
  font-family: var(--quick-sand-font);
  text-align: center;
  letter-spacing: 1px;
  color: #fff;
}
.page-breadcrumb {
  background: hsl(0deg 0% 100% / 10%);
  border-radius: 15px;
  opacity: 1;
  -webkit-backdrop-filter: blur(35px);
          backdrop-filter: blur(35px);
  padding: 20px;
  color: #fff;
}
.page-breadcrumb a {
  font-size: 20px;
  font-family: Muli;
  font-weight: 400;
  text-align: left;
  letter-spacing: 0.5px;
  line-height: 34px;
  color: #fff;
}
.row.title-banner-wrapper {
  margin: 50px 0 0;
  min-height: 300px;
  justify-content: center;
  align-items: center;
}
.row.title-banner-wrapper .banner-container {
  display: block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  text-align: center;
}
.row.title-banner-wrapper div {
  height: 100%;
}

.banner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  margin: 0px 0px;
  padding: 0;
}

@media (max-width: 1599px){
.main_title .row {
    background-size: 100% 100%;
}}

/* SECTIONS */
section.cards-section{
  background: hsl(40deg 88% 53% / 10%);
  padding-bottom: 50px;
}
.community-activity-page .cards-section {
  margin-top: 50px;
}
.cards-section .col {
  margin-bottom: 20px;
}

h2.section-title {
  font-size: 35px;
  font-family: var(--quick-sand-font);
  letter-spacing: 0.88px;
  line-height: 71px;
  color: #000000;
  opacity: 1;
  text-align: center;
  margin-bottom: 20px;
}
.common-section, .about-us-section {
  border: var(--primary-brand-color);
  border-style: solid;
  border-width: 1px;
  padding: 20px;
  border-radius: 15px;
  margin: 10px 0px;
}

.common-section .title {
  text-align: center;
}

.about-us-section:hover, .common-section:hover{
  border: var(--secondary-brand-color);
  border-style: solid;
  border-width: 1px;
  padding: 20px;
  border-radius: 15px;
  margin: 10px 0px;
  box-shadow: 10px 10px 5px var(--secondary-brand-color);
}

.two-image-section {
  margin: 20px 0px;
}
.two-image-section p{
  color: #fff;
}
.two-image-section .info-side{
  display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: self-start;
    grid-gap: 0;
    gap: 0;
    color:#fff
}
.btn{
  text-align: center;
  font: normal normal bold 24px/50px var(--quick-sand-font);
  letter-spacing: 0.6px;
  color: #FFFFFF;
  opacity: 1;
  background: var(--primary-brand-color) 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  border: none;
  display: inline;
}

.btn-primary:hover{
  text-align: center;
  font: normal normal bold 24px/50px var(--quick-sand-font);
  letter-spacing: 0.6px;
  color: #FFFFFF;
  opacity: 1;
  background: var(--secondary-brand-color) 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  border: none;
}

.two-image-section .btn{
  text-align: center;
  font: normal normal bold 24px/50px var(--quick-sand-font);
  letter-spacing: 0.6px;
  color: #FFFFFF;
  opacity: 1;
  background: var(--primary-brand-color) 0% 0% no-repeat padding-box;
  border-radius: 15px;
  opacity: 1;
  border: none;
}

/* icon section component */
.box-part{
  background:#F0FFFF;
  height: 100%;
  padding:60px 10px;
  margin:30px 0px;
   box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
margin-bottom: 25px;
}

.box-part:hover{
  background:var(--secondary-brand-color);
}

.box-part a{
  color: var(--primary-brand-color);
}

.box-part:hover .fa , 
.box-part:hover .title , 
.box-part:hover .text {
  color:#fff;
  transition: all 1s ease-out;
}

.fa{
  color: var(--primary-brand-color);
}

/* CONTACT FORM */
.contact-form{
  background: #fff;
  width: 100%;
  box-shadow: 0px 2px 6px #00000029;
  margin: 10px;
}
.contact-form .form-control{
  border-radius:1rem;
  border: 2px solid;
  font-size:1.5rem;
}
.contact-form .form-group{
  margin: 10px;
}
.contact-form form{
  padding: 5%;
}

.contact-form h3{
  margin-bottom: 8%;
  margin-top: -10%;
  text-align: center;
  color: var(--secondary-brand-color);
}

/* Events Section */

.event-img {
  border-radius: 15px 15px 0 0;
  overflow: hidden;
  /* height: 305px; */
}
.event-img img{
object-fit: cover;
}
.event-content h3 {
  font-size: 22px;
  font-family: var(--quick-sand-font);
  text-align: left;
  line-height: 30px;
  font-weight: 700;
  color: #000000;
  opacity: 1;
  /* min-height: 80px; */
}
.event-content p {
  font-size: 20px;
  font-family: Muli;
  font-weight: 300;
  text-align: left;
  line-height: 35px;
  letter-spacing: 0.4px;
  color: #000000;
  opacity: 1;
}
.event-card {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 6px #00000029;
  border-radius: 10px;
  opacity: 1;
  margin: 20px 0;
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.event-card:hover .event-img {
  overflow: visible;
  height: 100%;
}
.event-card:hover .event-content {
  opacity: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}
.event-conetnt-hover ul.event-date-time-location{
  background-color: var(--primary-brand-color);
  margin: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.event-conetnt-hover ul.event-organised-by li,.event-conetnt-hover ul.event-organised-by{
display: flex;
justify-content: flex-start;
align-items: center;
grid-gap: 10px;
gap: 10px;
max-width: 348px;
width: 100%;
margin: 0 auto;
}
.event-conetnt-hover ul.event-organised-by li img {
  width: 50px;
  height: 50px;
  object-fit: cover;
}
.event-conetnt-hover ul.event-organised-by li h6.price,.event-conetnt-hover ul.event-organised-by li p.price{
  font-size: 14px;
  font-family: Muli;
  text-align: revert;
  font-weight: 700;
  line-height: 35px;
  letter-spacing: 0.35px;
  color: #000000;
  opacity: 1;
}
.event-conetnt-hover ul.event-organised-by li p.price{
font-weight: 400 !important;
}
.event-conetnt-hover ul.event-date-time-location {
  display: flex;
  justify-content: space-around;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
  margin: 0px auto 15px;
  padding: 10px;
  background-color: var(--secondary-brand-color);
  border-radius: 10px;
  opacity: 1;
  max-width: 348px;
  width: 100%;
}
.event-conetnt-hover ul.event-date-time-location span.price {
  font-size: 12px;
  font-family: Poppins;
  font-weight: 400;
  text-align: left;
  line-height: 21px;
  color: #FFFFFF;
  opacity: 1;
}
.event-main-content h3 {
  max-width: 348px;
  width: 100%;
  margin: 0 auto;
  font-family: var(--quick-sand-font);
  font-size: 22px;
  text-align: left;
  line-height: 33px;
  color: #000000;
  opacity: 1;
}
.event-main-content p.price {
  max-width: 348px;
  width: 100%;
  margin: 0 auto;
  text-align: left;
  font-size: 20px;
  font-family: Muli;
  line-height: 29px;
  letter-spacing: 0.4px;
  color: #000000;
  opacity: 1;
}
a.event-btn {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background-color: var(--secondary-brand-color);
  border-radius: 8px;
  opacity: 1;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
  margin: 20px 11px;
}
a.event-btn span.text {
  font-size: 18px;
  font-family: var(--quick-sand-font);
  color: #FFFFFF;
  opacity: 1;
  background: transparent;
}
a.event-btn span.btn {
  background: #fff;
  border-radius: 7px;
}
a.event-btn span.btn .fa {
  color: var(--secondary-brand-color);
  font-size: 13px;
  text-align: left;
  letter-spacing: 0.33px;
}
.event-conetnt-hover {
  max-width: 348px;
  margin: 0 auto;
  width: 100%;
  padding: 20px 0;
}
section#events-o {
  position: relative;
}
section#events-o .owl-prev,section#events-o .owl-next {
  top: 50%;
}
.event-conetnt-hover {
  position: absolute;
  max-width: 370px;
  width: 100%;
  margin: 0 auto;
  top: 10px;
  height: 94%;
  left: 50%;
  transform: translateX(-50%);
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 6px #00000029;
  border-radius: 15px;
  opacity: 0;
  transition-delay: 0.3s;
  z-index: -1;
}
.event-card:hover .event-img img{
border-radius: 15px;
}
.event-content {
  padding: 10px 15px;
  transition: 0.3s ease-in-out;
}
.event-card:hover .event-conetnt-hover{
opacity: 1;
z-index: 1;
transition: 0.3s ease-in-out;
}
.event-img img{
border-radius: 15px;
}
.event-content {
  padding: 10px 15px;
  position: absolute;
  width: 100%;
  bottom: 0;
  transition: 0.3s ease-in-out;
  background: #fff;
  border-radius: 0 0 15px 15px;
  opacity: 1;
  z-index: 1
}
.event-conetnt-hover ul.event-date-time-location li i {
  font-style: normal;
  color: #FFFFFF;
}

/* NAVBAR SECTION */
.navbar{
  height: 100px;
}

.navbar-light .navbar-nav .nav-link{
  color: #333333;
  font-size: 20px;
  font-weight: bold;
}
.navbar .nav-link:hover{
  color: var(--primary-brand-color)!important;
}

.navbar .show{
  background-color: var(--secondary-brand-color);
}
.navbar-nav {
  position: absolute;
  right: 100px;
}
.navbar-nav .show{
  background-color: #fff;
}
.show .navbar-nav{
  padding-bottom: 20px;
  position: static;
}

.navbar .show .nav-link{
  color: #fff;
  font-size: 20px;
  font-weight: bold;
}

/* CARDS */
.card .btn{
  padding-bottom: 1px;
}

/* ON LARGE SCREENS*/
@media only screen and (min-width: 768px) {
 .nabar-brand {
    position:absolute;
    left: 100px;
  }
}
/* Google Fonts */
/* Basic Style */
:root {
  --quick-sand-font: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  --purple-color:#5819B8;
  --font-color:#121212;
  --black-color: #000;
  --primary-brand-color: #A3C777;
  --secondary-brand-color: green
}

/*---------*/


html body{
  margin:0;
  padding:0;
  -ms-word-wrap:break-word;
  word-wrap:break-word;
  background-color:#fff;
  position:relative;
  font-size: 20px;
  color: var(--black-color);
  overflow-x: hidden;
  font-family: var(--quick-sand-font);
}
label {
  margin-bottom: 0 !important;
}
img{
  margin:0;
  padding:0;
  border:none;
  max-width:100%;
  height:auto;
}
section img{
  max-width:100%;
}
h1,h2,h3,h4,h5,h6{
  margin:0;
  padding:10px 0;
  line-height: 1.4;
  letter-spacing: 1px;
  font-family: source sans pro;
  font-weight: 600;
}
h2 {
  font-size: 35px;
  font-family: var(--quick-sand-font);
  letter-spacing: 0.88px;
  line-height: 71px;
  color: #000000;
  opacity: 1;
  text-align: center;
  margin-bottom: 20px;
}
p{
  margin:0 0;
  padding:0;
  font-size:20px;
  font-weight:normal;
  font-family: source sans pro;
}
ul li a{
  letter-spacing: 0px;
  font-family: var(--quick-sand-font);
  color: var(--font-color);
}
body a{
  text-decoration:none;
  color: var(--font-color);
}
a:hover, a:focus{
  text-decoration:none !important;
}
ul,ol{
  margin:0 0 0 15px;
  padding:0;
  list-style: none;
}
.training-list li {
  display: inline-block;
  margin:10px;
  font-size: 20px;
  border: var(--primary-brand-color);
  border-style: solid;
  border-width: 1px;
  padding: 20px;
  border-radius: 15px;
  width: -webkit-fill-available;
  max-width: 510px;
  min-width: 300px;
}
small{
  font-size:14px !important;
}

textarea{
  border:1px solid #424648;
  font-size:16px;
  padding:10px 10px;
  margin:0 0 0px 0;
  height:auto;
  width: 100%;
  background: transparent;
}
section{
  background-size: cover;
  background-repeat: no-repeat;
  padding: 50px 0;
}
select{
  width: 100%;
  padding: 6px;
}
::selection {
    background: var(--theme-primary-color);
    color: #fff;
}
.fa-solid {
  font-weight: 600;
  font-family: 'Font Awesome 5 Free';
}
.sticky{
  margin:0;
  padding:0;
  background-color:#d7d7d7;
}

.footer {
    color: #fff;
    font-size: 20px;
    margin-bottom: 0px;
    margin-top: 20px;
    padding: 10px 10px 0px 10px;
    bottom: 0;
    width: 100%;
    /* Height of the footer*/
    padding-bottom: 20px;
}

.footer .nav-link {
  color: var(--primary-brand-color)
}

.copy-right-footer {
  background-color: #d7d7d7;
  color: #000;
}

.main_title h1 {
  font-size: 40px;
  font-family: var(--quick-sand-font);
  text-align: center;
  letter-spacing: 1px;
  color: #fff;
}
.page-breadcrumb {
  background: hsl(0deg 0% 100% / 10%);
  border-radius: 15px;
  opacity: 1;
  -webkit-backdrop-filter: blur(35px);
          backdrop-filter: blur(35px);
  padding: 20px;
  color: #fff;
}
.page-breadcrumb a {
  font-size: 20px;
  font-family: Muli;
  font-weight: 400;
  text-align: left;
  letter-spacing: 0.5px;
  line-height: 34px;
  color: #fff;
}
.row.title-banner-wrapper {
  margin: 0px 0 0;
  min-height: 300px;
  justify-content: center;
  align-items: center;
}
.row.title-banner-wrapper .banner-container {
  display: block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  text-align: center;
}
.row.title-banner-wrapper div {
  height: 100%;
}

.banner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  margin: 0px 0px;
  padding: 0;
}

@media (max-width: 1599px){
.main_title .row {
    background-size: 100% 100%;
}}

/* SECTIONS */
section {
  background: hsl(40deg 88% 53% / 10%);
  padding-bottom: 50px;
}
section.cards-section{
  background: hsl(40deg 88% 53% / 10%);
  padding-bottom: 50px;
}
.community-activity-page .cards-section {
  margin-top: 50px;
}
.cards-section .col {
  margin-bottom: 20px;
}

h2.section-title {
  font-size: 35px;
  font-family: var(--quick-sand-font);
  letter-spacing: 0.88px;
  line-height: 71px;
  color: #000000;
  opacity: 1;
  text-align: center;
  margin-bottom: 20px;
}
.common-section, .about-us-section {
  border: var(--primary-brand-color);
  border-style: solid;
  border-width: 1px;
  padding: 20px;
  border-radius: 15px;
  margin: 10px 0px;
}

.common-section .title {
  text-align: center;
}

.about-us-section:hover, .common-section:hover{
  border: var(--secondary-brand-color);
  border-style: solid;
  border-width: 1px;
  padding: 20px;
  border-radius: 15px;
  margin: 10px 0px;
  box-shadow: 10px 10px 5px var(--secondary-brand-color);
}

.two-image-section {
  margin: 20px 0px;
}
.two-image-section p{
  color: #fff;
}
.two-image-section .info-side{
  display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: self-start;
    grid-gap: 0;
    gap: 0;
    color:#fff
}
.btn{
  text-align: center;
  font: normal normal bold 24px/50px var(--quick-sand-font);
  letter-spacing: 0.6px;
  color: #FFFFFF;
  opacity: 1;
  background: var(--primary-brand-color) 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  border: none;
  display: inline;
}

.btn-primary:hover{
  text-align: center;
  font: normal normal bold 24px/50px var(--quick-sand-font);
  letter-spacing: 0.6px;
  color: #FFFFFF;
  opacity: 1;
  background: var(--secondary-brand-color) 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  border: none;
}

.two-image-section .btn{
  text-align: center;
  font: normal normal bold 24px/50px var(--quick-sand-font);
  letter-spacing: 0.6px;
  color: #FFFFFF;
  opacity: 1;
  background: var(--primary-brand-color) 0% 0% no-repeat padding-box;
  border-radius: 15px;
  opacity: 1;
  border: none;
}

/* icon section component */
.box-part{
  background:#F0FFFF;
  height: 100%;
  padding:60px 10px;
  margin:30px 0px;
   box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
margin-bottom: 25px;
}

.box-part:hover{
  background:var(--secondary-brand-color);
}

.box-part a{
  color: var(--primary-brand-color);
}

.box-part:hover .fa , 
.box-part:hover .title , 
.box-part:hover .text {
  color:#fff;
  transition: all 1s ease-out;
}

.fa{
  color: var(--primary-brand-color);
}

/* CONTACT FORM */
.contact-form{
  background: #fff;
  width: 100%;
  box-shadow: 0px 2px 6px #00000029;
  margin: 10px;
}
.contact-form .form-control{
  border-radius:1rem;
  border: 2px solid;
  font-size:1.5rem;
}
.contact-form .form-group{
  margin: 10px;
}
.contact-form form{
  padding: 5%;
}

.contact-form h3{
  margin-bottom: 8%;
  margin-top: -10%;
  text-align: center;
  color: var(--secondary-brand-color);
}

/* Events Section */

.event-img {
  border-radius: 15px 15px 0 0;
  overflow: hidden;
  /* height: 305px; */
}
.event-img img{
object-fit: cover;
}
.event-content h3 {
  font-size: 22px;
  font-family: var(--quick-sand-font);
  text-align: left;
  line-height: 30px;
  font-weight: 700;
  color: #000000;
  opacity: 1;
  /* min-height: 80px; */
}
.event-content p {
  font-size: 20px;
  font-family: Muli;
  font-weight: 300;
  text-align: left;
  line-height: 35px;
  letter-spacing: 0.4px;
  color: #000000;
  opacity: 1;
}
.event-card {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 6px #00000029;
  border-radius: 10px;
  opacity: 1;
  margin: 20px 0;
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.event-card:hover .event-img {
  overflow: visible;
  height: 100%;
}
.event-card:hover .event-content {
  opacity: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}
.event-conetnt-hover ul.event-date-time-location{
  background-color: var(--primary-brand-color);
  margin: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.event-conetnt-hover ul.event-organised-by li,.event-conetnt-hover ul.event-organised-by{
display: flex;
justify-content: flex-start;
align-items: center;
grid-gap: 10px;
gap: 10px;
max-width: 348px;
width: 100%;
margin: 0 auto;
}
.event-conetnt-hover ul.event-organised-by li img {
  width: 50px;
  height: 50px;
  object-fit: cover;
}
.event-conetnt-hover ul.event-organised-by li h6.price,.event-conetnt-hover ul.event-organised-by li p.price{
  font-size: 14px;
  font-family: Muli;
  text-align: revert;
  font-weight: 700;
  line-height: 35px;
  letter-spacing: 0.35px;
  color: #000000;
  opacity: 1;
}
.event-conetnt-hover ul.event-organised-by li p.price{
font-weight: 400 !important;
}
.event-conetnt-hover ul.event-date-time-location {
  display: flex;
  justify-content: space-around;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
  margin: 0px auto 15px;
  padding: 10px;
  background-color: var(--secondary-brand-color);
  border-radius: 10px;
  opacity: 1;
  max-width: 348px;
  width: 100%;
}
.event-conetnt-hover ul.event-date-time-location span.price {
  font-size: 12px;
  font-family: Poppins;
  font-weight: 400;
  text-align: left;
  line-height: 21px;
  color: #FFFFFF;
  opacity: 1;
}
.event-main-content h3 {
  max-width: 348px;
  width: 100%;
  margin: 0 auto;
  font-family: var(--quick-sand-font);
  font-size: 22px;
  text-align: left;
  line-height: 33px;
  color: #000000;
  opacity: 1;
}
.event-main-content p.price {
  max-width: 348px;
  width: 100%;
  margin: 0 auto;
  text-align: left;
  font-size: 20px;
  font-family: Muli;
  line-height: 29px;
  letter-spacing: 0.4px;
  color: #000000;
  opacity: 1;
}
a.event-btn {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background-color: var(--secondary-brand-color);
  border-radius: 8px;
  opacity: 1;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
  margin: 20px 11px;
}
a.event-btn span.text {
  font-size: 18px;
  font-family: var(--quick-sand-font);
  color: #FFFFFF;
  opacity: 1;
  background: transparent;
}
a.event-btn span.btn {
  background: #fff;
  border-radius: 7px;
}
a.event-btn span.btn .fa {
  color: var(--secondary-brand-color);
  font-size: 13px;
  text-align: left;
  letter-spacing: 0.33px;
}
.event-conetnt-hover {
  max-width: 348px;
  margin: 0 auto;
  width: 100%;
  padding: 20px 0;
}
section#events-o {
  position: relative;
}
section#events-o .owl-prev,section#events-o .owl-next {
  top: 50%;
}
.event-conetnt-hover {
  position: absolute;
  max-width: 370px;
  width: 100%;
  margin: 0 auto;
  top: 10px;
  height: 94%;
  left: 50%;
  transform: translateX(-50%);
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 6px #00000029;
  border-radius: 15px;
  opacity: 0;
  transition-delay: 0.3s;
  z-index: -1;
}
.event-card:hover .event-img img{
border-radius: 15px;
}
.event-content {
  padding: 10px 15px;
  transition: 0.3s ease-in-out;
}
.event-card:hover .event-conetnt-hover{
opacity: 1;
z-index: 1;
transition: 0.3s ease-in-out;
}
.event-img img{
border-radius: 15px;
}
.event-content {
  padding: 10px 15px;
  position: absolute;
  width: 100%;
  bottom: 0;
  transition: 0.3s ease-in-out;
  background: #fff;
  border-radius: 0 0 15px 15px;
  opacity: 1;
  z-index: 1
}
.event-conetnt-hover ul.event-date-time-location li i {
  font-style: normal;
  color: #FFFFFF;
}

/* NAVBAR SECTION */
.navbar{
  height: 100px;
}

.navbar-light .navbar-nav .nav-link{
  color: #333333;
  font-size: 20px;
  font-weight: bold;
}
.navbar .nav-link:hover{
  color: var(--primary-brand-color)!important;
}

.navbar .show{
  background-color: var(--secondary-brand-color);
  z-index: 3;
}
.navbar-nav {
  position: absolute;
  right: 100px;
}
.navbar-nav .show{
  background-color: #fff;
}
.show .navbar-nav{
  padding-bottom: 20px;
  position: static;
}

.navbar .show .nav-link{
  color: #fff;
  font-size: 20px;
  font-weight: bold;
}

/* CARDS */
.card .btn{
  padding-bottom: 1px;
}
.list-card ul{
  text-align: center;
  list-style: none;
}

.list-card:hover {
  border:none;
  z-index: 5;
  box-shadow: 10px 10px 5px var(--secondary-brand-color);
}
/* ON LARGE SCREENS*/
@media only screen and (min-width: 768px) {
 .nabar-brand {
    position:absolute;
    left: 100px;
  }
}
/* Basic Style */
:root {
  --quick-sand-font: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  --purple-color:#5819B8;
  --font-color:#121212;
  --black-color: #000;
  --primary-brand-color: #A3C777;
  --secondary-brand-color: #6934ab
}

/*---------*/

.card .btn{
  position:absolute;
  bottom: 10px;
}

/* BANNER STYLING */
.banner {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 500 px;
    position:relative;
    text-align: center;
    color: white;
  }
  
  .banner img {
    height: 100%;
    object-fit: cover;
  }
  
  .banner-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
    
  #home-banner img{
    filter: brightness(50%)
  }

  /* MISSION SECTION */
  .mission-section {
    margin-top: 50px;
    margin-bottom: 50px;
    background: hsl(40deg 88% 53% / 10%);
  }
  .mission-section-text{
    padding: 30px;
  }
  .two-image-section p{
    color: #000;
  }

  /* APPROACH SECTION */
  .our-objectives-section {
    margin-bottom: 20px;
  }
 .join-us-section .card{
    padding-bottom: 65px;
    text-align: center;
  }
  .join-us-section .card .btn{
    position:absolute;
    bottom: 10px;
    left: 30%;
  }
  .join-us-section .col {
    padding: 0px;
  }

  /* ABOUT US SECTION */
  .common-section {
    border: none;
  }
 .common-section:hover {
    border:none;
    box-shadow: 10px 10px 5px var(--primary-brand-color);
  }

  /* SUPPORT SERVICES */
  .support-services-section .card {
    padding-bottom: 55px;
  }

  /* PROGRAMS SECTION */
  .programs-section .card {
    padding-bottom: 55px;
  }

  .programs-section p {
    display: -webkit-box;
    max-width: 200px;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  /* SUPPORT SERVICES SECTION */

.event-img {
  border-radius: 15px 15px 0 0;
  overflow: hidden;
  /* height: 305px; */
}
.event-img img{
object-fit: cover;
}
.event-content h3 {
  font-size: 22px;
  font-family: var(--quick-sand-font);
  text-align: left;
  line-height: 30px;
  font-weight: 700;
  color: #000000;
  opacity: 1;
  /* min-height: 80px; */
}
.event-content p {
  font-size: 20px;
  font-family: Muli;
  font-weight: 300;
  text-align: left;
  line-height: 35px;
  letter-spacing: 0.4px;
  color: #000000;
  opacity: 1;
}
.event-card {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 6px #00000029;
  border-radius: 10px;
  opacity: 1;
  margin: 20px 0;
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.event-card:hover .event-img {
  overflow: visible;
  height: 100%;
}
.event-card:hover .event-content {
  opacity: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}
.event-conetnt-hover ul.event-date-time-location{
  background-color: var(--primary-brand-color);
  margin: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.event-conetnt-hover ul.event-organised-by li,.event-conetnt-hover ul.event-organised-by{
display: flex;
justify-content: flex-start;
align-items: center;
grid-gap: 10px;
gap: 10px;
max-width: 348px;
width: 100%;
margin: 0 auto;
}
.event-conetnt-hover ul.event-organised-by li img {
  width: 50px;
  height: 50px;
  object-fit: cover;
}
.event-conetnt-hover ul.event-organised-by li h6.price,.event-conetnt-hover ul.event-organised-by li p.price{
  font-size: 14px;
  font-family: Muli;
  text-align: revert;
  font-weight: 700;
  line-height: 35px;
  letter-spacing: 0.35px;
  color: #000000;
  opacity: 1;
}
.event-conetnt-hover ul.event-organised-by li p.price{
font-weight: 400 !important;
}
.event-conetnt-hover ul.event-date-time-location span.price {
  font-size: 12px;
  font-family: Poppins;
  font-weight: 400;
  text-align: left;
  line-height: 21px;
  color: #FFFFFF;
  opacity: 1;
}
.event-main-content h3 {
  max-width: 348px;
  width: 100%;
  margin: 0 auto;
  font-family: var(--quick-sand-font);
  font-size: 22px;
  text-align: left;
  line-height: 33px;
  color: #000000;
  opacity: 1;
}
.event-main-content p.price {
  max-width: 348px;
  width: 100%;
  margin: 0 auto;
  text-align: left;
  font-size: 20px;
  font-family: Muli;
  line-height: 29px;
  letter-spacing: 0.4px;
  color: #000000;
  opacity: 1;
}
a.event-btn {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background-color: var(--secondary-brand-color);
  border-radius: 8px;
  opacity: 1;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
  margin: 20px 11px;
}
a.event-btn span.text {
  font-size: 18px;
  font-family: var(--quick-sand-font);
  color: #FFFFFF;
  opacity: 1;
  background: transparent;
}
a.event-btn span.btn {
  background: #fff;
  border-radius: 7px;
}
a.event-btn span.btn .fa {
  color: var(--secondary-brand-color);
  font-size: 13px;
  text-align: left;
  letter-spacing: 0.33px;
}
.event-conetnt-hover {
  max-width: 348px;
  margin: 0 auto;
  width: 100%;
  padding: 20px 10px;
}
section#events-o {
  position: relative;
}
section#events-o .owl-prev,section#events-o .owl-next {
  top: 50%;
}
.event-conetnt-hover {
  position: absolute;
  max-width: 370px;
  width: 100%;
  margin: 0 auto;
  top: 10px;
  height: 94%;
  left: 50%;
  transform: translateX(-50%);
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 6px #00000029;
  border-radius: 15px;
  opacity: 0;
  transition-delay: 0.3s;
  z-index: -1;
}
.event-card:hover .event-img img{
border-radius: 15px;
}
.event-content {
  padding: 10px 15px;
  transition: 0.3s ease-in-out;
}
.event-card:hover .event-conetnt-hover{
opacity: 1;
z-index: 1;
transition: 0.3s ease-in-out;
}
.event-img img{
border-radius: 15px;
}
.event-content {
  padding: 10px 15px;
  position: absolute;
  width: 100%;
  bottom: 0;
  transition: 0.3s ease-in-out;
  background: #fff;
  border-radius: 0 0 15px 15px;
  opacity: 1;
  z-index: 1;
  height: 90%;
}

/* CIRCLE CARD COMPONENT */
.circle-card {
  border: #000;
  border-style: solid;
  border-radius: 50%;
  width: 300px;
  height: 300px;
  display: table-cell;
  vertical-align: middle;
  font-size: 50px;
  text-align: center;
  color:#000;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 6px #00000029;
  opacity: 1;
  margin: 20px 0;
  position: relative;
}

.circle-card:hover {
  border: var(--secondary-brand-color);
  border-style: double;
  border-radius: 50%;
  width: 300px;
  height: 300px;
  display: table-cell;
  vertical-align: middle;
  font-size: 50px;
  text-align: center;
  background: var(--primary-brand-color);
  color:#fff
}

.circle-card .circle-card-content p {
  font-size: 20px;
  font-weight: 700;
}

.circle-card-content-hover{
  position: absolute;
  max-width: 370px;
  width: 100%;
  margin: 0 auto;
  top: 10px;
  height: 100%;
  left: 50%;
  transform: translateX(-50%);
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 6px #00000029;
  border-radius: 15px;
  opacity: 0;
  transition-delay: 0.3s;
  z-index: -1;
  color: var(--secondary-brand-color)
}
.circle-card-content-hover h3{
  font-size: 22px;
  color: #000;
}
.circle-card-content-hover p{
  padding: 10px;
  line-height: 25px;
}

.circle-card-content-hover a{
  font-size: 20px;
  position: absolute;
  bottom: 20px;
  left: 20px;
  color: var(--primary-brand-color)
}


.circle-card:hover .circle-card-content-hover{
  opacity: 1;
  z-index: 1;
  transition: 0.3s ease-in-out;
}
